import React from 'react';
import { Container, Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import schoolImg from './Images/school.jpeg'
const academicDetails = [
    {
        title: "Bachelor of Information Technology",
        institute: "Sri Ramakrishna Institute of Technology",
        year:"2016 - 2020",
        location: "Coimbatore, TamilNadu",
        result: "7.3 CGPA",
        description:"SRIT is one among the most eminent Educational Institutions in Coimbatore district. SRIT is recognized as one of the leaders in engineering education, research and application of knowledge to the betterment of the society and the country. ",
        link:"http://www.srit.org/",
        image: "http://www.srit.org/images/facilities/infrasture-03.jpg",
    },
    {
        title: "Higher Secondary School",
        institute: "Keartiman Matriculation Higher Secondary School",
        year:"2014 - 2016",
        location: "Coimbatore, TamilNadu",
        result: "80%",
        description:"My School Keartiman Matriculation Higher Secondary School",
        link:"",
        image:""
    },
    {
        title: "Secondary School",
        institute: "Keartiman Matriculation Higher Secondary School",
        year:"2012 - 2014",
        location: "Coimbatore, TamilNadu",
        result: "97%",
        description:"My School Keartiman Matriculation Higher Secondary School",
        link:"",
        image: ""
    },
];
export default function Academic(){
    return(
        <div> 
            <Paper elevation={3} style={{padding:"10px"}}> 
                <Typography variant="h6" style={{marginLeft:"20px",marginBottom:"10px"}}>
                    Academic Credentials
                </Typography>
                <Divider />   
                <br/>    
                {academicDetails.map((data) => (  
                    <Container>                   
                        <Grid container spacing={3}>       
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h5" >
                                {data.title}
                                </Typography>
                                <br/>                   
                                <Typography variant="h6" >
                                {data.institute}
                                </Typography>                            
                                <Typography variant="h8" >
                                {data.location}
                                </Typography>
                                <br/> 
                                <Typography variant="h7" >
                                Year of completion {data.year}
                                </Typography>
                                <br/> 
                                <Typography variant="h7" >
                                Completed {data.title} with score of {data.result}
                                </Typography>
                                <br/> 
                                <br/>
                                <Typography variant="h8" >
                                    {data.description}
                                </Typography>
                                <br/> 
                                <br/>
                                {data.link!==""?  <Typography variant="h8" >
                                <a href={data.link}>{data.link}</a>
                                </Typography>:<></>}                              
                                <br/> 
                                <br/>                              
                            </Grid>
                        <Grid item xs={12} sm={4}> 
                        {data.image!==""?<Paper elevation={3} style={{padding:"5px"}}>  
                            {data.image === "" ?"":<img src={data.image} alt="" width="100%"
                            height="200px"></img>}                 
                                
                                <Divider />                               
                            </Paper>:<></>}                       
                            
                        </Grid>       
                    </Grid> 
                    <Divider />
                    <br/>
                </Container>      
                ))} 
            </Paper>       
        </div>
    );
}