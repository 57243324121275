import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export default function Header(props) {
  const classes = useStyles(); 
  const { title, UserName , renderPage} = props;  
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>     
      <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="left"
          noWrap          
          className={classes.toolbarTitle}
        >
          {UserName}
        </Typography>    
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="left"
          noWrap
          className={classes.toolbarTitle}
        >
          {title}
        </Typography>
        
        <a href="https://twitter.com/PreethiHarihar3?s=08" style={{marginTop:"5px"}}>
        <TwitterIcon style={{color:'black'}}/>       
        </a>
        <a href="https://www.linkedin.com/in/preethi-hariharan-44a877139" style={{marginTop:"5px",marginRight:"5px"}}>
        <LinkedInIcon style={{color:'black'}}/>       
        </a>
        <a href="https://accounts.google.com/" style={{marginTop:"5px",marginRight:"5px"}}>
          <EmailIcon style={{color:'black'}}/>       
        </a>             
      </Toolbar>  
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
      <Typography
          variant="h7"
          align="left"
          onClick={()=>renderPage("home")}
        >
          <Link color="inherit"
            noWrap            
            variant="body2"            
            className={classes.toolbarLink} >Home</Link>          
        </Typography>
        <Typography
          variant="h7"
          align="left"
          onClick={()=>renderPage("Academic")}
        >
          <Link color="inherit"
            noWrap            
            variant="body2"            
            className={classes.toolbarLink} >Academic</Link>          
        </Typography>
        <Typography
          variant="h7"
          align="left"
          onClick={()=>renderPage("InterShip")}
        >
          <Link color="inherit"
            noWrap            
            variant="body2"            
            className={classes.toolbarLink} >InterShip</Link>          
        </Typography>
        <Typography
          variant="h7"
          align="left"
          onClick={()=>renderPage("Project")}
        >
          <Link color="inherit"
            noWrap            
            variant="body2"            
            className={classes.toolbarLink} >Project</Link>          
        </Typography>
        <Typography
          variant="h7"
          align="left"
          onClick={()=>renderPage("Achievement")}
        >
          <Link color="inherit"
            noWrap            
            variant="body2"            
            className={classes.toolbarLink} >Achievement</Link>          
        </Typography>
      </Toolbar>        
    </React.Fragment>
  );
}
