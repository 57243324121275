import React from 'react';
import { Container, Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
const acheivementDetails = ["Student Entrepreneur of the year 2019-2020 award from EO-GSEA and nominated for South-Asian level event.",
"Special Award on Innovation track at Stamford University, Thailand by IEEE YESIST12.",
"Strong Intellectual property (IP) award from IICDC- Indian Innovation Challenge and Design Contest, 2018 at IIM B (Indian Institute of Management, Bangalore) and Texas Instruments.",
"Secured third prize in Fifth Annual Business Plan competition, 2018 from PSG Institute of Management and University of Toledo, United States",
"Won 1st prize in DEBATE (War of Words) from KGISL, Coimbatore, 2017",
"Won 1st prize on Elocution competition, Keartiman Academy, 2015",
"District Champion in Yoga competition in year,2012.",
"Part of a Guinness record on theme of World largest drawing on Pollution Control Awareness, Codissia, Coimbatore, 2009"    
];
export default function Acheivement(){
    return(
        <div> 
            <Paper elevation={3} style={{padding:"10px"}}> 
            <br/>
                <Typography variant="h6" style={{marginLeft:"20px",marginBottom:"10px"}}>
                Acheivements         
                </Typography>
                <Divider />  
                <Container>     
                    <Typography variant="h7" style={{marginLeft:"20px",marginBottom:"10px"}}>
                            {acheivementDetails.map((item) =>                           
                                    <ul>* {item}</ul>
                            )}           
                    </Typography>
                    <Divider />
                    <br/>                   
                </Container>
            </Paper>               
        </div>
    );
}