import React from 'react';
import { Container, Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const internshipDetails = [
    {
        organization: "DUCEN IT ",
        Designation: "Full Stack Software Engineering Intern | Chennai, TN | Jan 2020 – Oct 2020",        
        description:"Ducen IT is a booming tech company that owns a Business Intelligence software that accommodates every feature provided by the market leaders with its very own MVP. I have been selected as an intern and later transformed to an employee. I work as a full stack developer.",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        list:[]
    },
    {
        organization: "INTERNSHALA",
        Designation: "Campus Ambassador | Gurugram, HR | July 2018 – Jan 2020",        
        description:"Internshala is India's no.1 internship and training platform with 40000+ paid internships in Engineering, MBA, media, law, arts, and other streams. I have been selected as a Campus Ambassador of Internshala representing Sri Ramakrishna Institute of Technology as Student Partner.",
        link:"https://internshala.com/",
        image: "https://upload.wikimedia.org/wikipedia/en/8/8b/Internshala_company_logo.png",
        list:["To educate students about placement opportunities and prevailing expectation from industries, by conducting interactive seminars. I done then single handily for about 2000 students with in the campus. ","Guided students to land their dream internship by meeting the expectation of the recruiters."]
    },
    {
        organization: "UNACADEMY",
        Designation: "Educator | Bengaluru, KA | November 2018 – Jan 2020",        
        description:"Unacademy is India's largest free education initiative. It is an educational technology company based in Bangalore, India. Started in 2015, it is a platform that offers online education in India. I was chosen as Unacademy educator in stream of aptitude for cat, gate and placements.",
        link:"https://unacademy.com/",
        image: "https://static.uacdn.net/production/_next/static/images/logo.svg",
        list:["I would choose study materials and developed lesson plans to enhance logical and numerical skills for individuals. ","Bring new strategy to grab the learners interest by easy teaching methodology."]
    },
];
export default function Internship(){
    return(
        <div> 
            <Paper elevation={3} style={{padding:"10px"}}> 
                <Typography variant="h6" style={{marginLeft:"20px",marginBottom:"10px"}}>
                InternShips         
                </Typography>
                <Divider />   
                <br/>    
                {internshipDetails.map((data) => (    
                    <Container>                
                        <Grid container spacing={3}>       
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h5" >
                                {data.organization} <img src={data.image} alt="" width="10%"
                            height="20px"></img>
                                </Typography>
                                <br/>                   
                                <Typography variant="h6" >
                                {data.Designation}
                                </Typography>                             
                                <br/>
                                <Typography variant="h8" >
                                    {data.description}
                                </Typography>
                                <br/>
                                <Typography variant="h8" >
                                    {data.list.map((item) =>                           
                                        <ul>* {item}</ul>
                                    )}
                                </Typography>                           
                                <Typography variant="h8" >
                                <a href={data.link}>{data.link}</a>
                                </Typography>
                                <br/>
                                <br/>
                                <Divider />
                            </Grid>
                    </Grid> 
                </Container>     
                ))} 
            </Paper>       
        </div>
    );
}