import React from 'react';
import { Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MainFeaturedHome from './MainFeaturedHome.jsx';
import ReactPlayer from "react-player";
import profileImg from './Images/preethi.jpeg';
const activityDetails = [
    {
        title: "Co-curricular Activities",                
        description:["Completed Business Skill training from Synergy, School of Business Skills.",
        "Published paper in UGC approved journal (IJSRR International Journal of Scientific Research and Review) by participating in National Conference.",
        "Completed “Do your venture” course from IIM Bangalore.",
        "Participated in International Symposium on Artificial Intelligence & Computer Vision (AICV) conducted by Anna University, Chennai.",
        "Certification on “Robotics and IoT” from Sri Ramakrishna Institute of Technology.",
        "Certification on PYTHON under the aegis of Infosys campus connect program."],       
    }, 
    {
        title: "Extra-curricular Activities",                
        description:["Core member of social education helping team Thadai Adhai Udai that guides IAS and IPS aspirants to crack UPSC exams and also tips from IAS and IPS officers.",
        "Member of NSS (National Service Scheme) and participated on a week campaign stressing Youth for Cleanliness, Karadimadai, Coimbatore.",
        "Member of Aesthetic and literary club",
        "Member of Youth Red Cross (YRC)",
        "Member of Engineering division team of Sri Ramakrishna Institute of Technology."],       
    },
] 
const personalInfo = {
    name: 'Preethi Hariharan',
    dob: '11/03/1999 ',
    mobile: '8220251694',
    email: 'Preethihariit03@gmail.com',
    linkedIn: 'https://www.linkedin.com/in/preethi-hariharan-44a877139',
    twitter: 'https://twitter.com/PreethiHarihar3?s=08',    
    nationality: 'Indian',
    language: ["English - Professional level", "Tamil ","Kanada"],
    hobbies: ["Motivational Speaking","Drawing","Blogging","Surfing Web"],
    skills :["Leadership","Public speaking","Team player","Communication"]
  };
const about = ["Campus Ambassador of Internshala, India's largest internship platform representing Sri Ramakrishna Institute of Technology.",
"Completed Integrated Design Project on Home Automation from Unimap( University of Malaysia, Perlis )",
"Public Speaker and Motivational Speaker.",
"Finalist of Indian Innovation Challenge and design contest,2018 by IIM Bangalore, Texas Instruments and AICTE.",
"Regional winner of Entrepreneurship Organization - Global Student Entrepreneur Award of 2019-2020.",
"Special Award on Innovation track on IEEE YESIST12 at Stamford University, Huahin, Thailand.",
"CAT, UPSC educator in Unacademy.",
"Annual Business Plan competition,2018 winners on PSG Institute of Management & Toledo University.",
"Social activist, abolish poverty through literacy!!!"]
const mainFeaturedHome = {
    title: 'Preethi Hariharan',
    description:
      "Full stack developer in Ducen IT",
    image: 'https://source.unsplash.com/random',
  };
export default function Home(){
    return(
        <div>
            <MainFeaturedHome post={mainFeaturedHome} />
            <Grid container spacing={3}>       
                <Grid item xs={12} sm={8}> 
                    <br/>  
                    <Typography variant="h5" gutterBottom>
                    About
                    </Typography>
                    <Divider />
                    <Typography variant="h7" gutterBottom>
                    {about.map((item) =>                           
                            <ul>* {item}</ul>
                        )}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                    Activity
                    </Typography>
                    <Divider />
                    {activityDetails.map((data) => ( 
                    <>
                    <Typography variant="h6" gutterBottom>
                    {data.title}
                    </Typography>
                    <Typography variant="h8" >
                    {data.description.map((item) =>                           
                            <ul>* {item}</ul>
                        )}
                    </Typography> 
                    </>))} 
                    <Typography variant="h5" gutterBottom>
                    Reach Me Out
                    </Typography>
                    <Divider />
                    <br/>
                    <a href={personalInfo.linkedIn} style={{marginTop:"5px",marginRight:"10px"}}>
                        <Typography variant="h8" gutterBottom>
                        LinkedIn 
                        </Typography>
                    </a>
                    <Typography variant="h8" gutterBottom>
                        ||
                        </Typography>
                    <a href={personalInfo.twitter} style={{marginTop:"5px",marginRight:"10px",marginLeft:"10px"}}>
                        <Typography variant="h8" gutterBottom>
                        Twitter 
                        </Typography>
                    </a>  
                    <Typography variant="h8" gutterBottom>
                        ||
                        </Typography>
                    <a href={personalInfo.email} style={{marginTop:"5px",marginRight:"10px",marginLeft:"10px"}}>
                        <Typography variant="h8" gutterBottom>
                        Gmail 
                        </Typography>
                    </a>                    
                </Grid>
                <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{padding:"5px"}}>
                    <Typography variant="h8" gutterBottom style={{padding:"5px",marginRight:"20%"}}>
                    <b>Profile</b>
                    <Divider />
                    </Typography>
                    <br/>
                    <img src={profileImg} alt="" width="70%" height="30%" style={{marginBottom:"10px", borderRadius:"30px", marginLeft:"15%"}}/>                    

                    {/* <ReactPlayer
                        url="https://www.youtube.com/watch?v=mhOy1mzAask"
                        width="100%"
                        height="200px"
                    />     */}
                    <br/>
                    <Typography variant="h8" gutterBottom style={{padding:"5px",marginRight:"20%"}}>
                      <b>D.O.B :</b>  {personalInfo.dob}
                    </Typography>
                    <Typography variant="h8" gutterBottom style={{padding:"5px"}}>
                      <b>Nationality :</b> {personalInfo.nationality}
                    </Typography>
                    <Divider /> 
                    <br/>
                    <Typography variant="h8" gutterBottom style={{padding:"5px"}}>
                    <b>Hobbies :</b> {personalInfo.hobbies.map((item) =>                           
                            <ul>* {item}</ul>
                        )}
                    </Typography>                               
                    <Typography variant="h8" gutterBottom style={{padding:"5px"}}>
                    <b>Languages Known :</b>{personalInfo.language.map((item) =>                           
                            <ul>* {item}</ul>
                        )}
                    </Typography>
                    <Typography variant="h8" gutterBottom style={{padding:"5px"}}>
                    <b>Skills set :</b>{personalInfo.skills.map((item) =>                           
                            <ul>* {item}</ul>
                        )}
                    </Typography>                    
                </Paper>
                </Grid>       
            </Grid>           
        </div>
    );
}