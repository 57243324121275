import React from 'react';
import { Container, Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from "react-player";
const internshipDetails = [
    {
        title: "SMART FISHING BOAT",                
        description:["Smartizing the boat by fixing special attachment to the existing one and make them as unmanned boat that navigates safely to maximum fishing zone and catch fishes.","It saves the cost and time of fishing and returns shore safely. "],
        technology:"LIDAR Technology, Artificial Intelligence and Android application.",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"https://youtu.be/PTv5Ja_HmX8",
        status:"Selected by My Government Innovation Challenge, DST & Texas Instruments Inc., India Innovation Challenge Design Contest 2018, Anchored by NSRCEL, IIM Bangalore -(One among top 30 teams across nation received 5lac cash prize",
        patent:"12 patents",
    },  
    {
        title: "PETRONA’S SMART FUEL MONITORING SYSTEM",                
        description:["Automobile sales and usage in our country are increasing day by day where also fuel theft is unawared mystery behind fuel pumping stations. ",
		"To avoid this we are implementing an artificial intelligence based fuel monitoring and tracking system. ",
		"So as soon as agent starts filling petrol in bike/car, the flow sensor is activated. ",
		"The system will help the owner of vehicle who is at remote location to perform the tasks of detecting the fuel theft and tracking the vehicle accurately and continuously. Also, the milage statistics to save from trouble."],
        technology:"Artificial Intelligence and Android application.",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"",
        status:"*(Selected by My Government Innovation Challenge, DST & Texas Instruments Inc., India Innovation Challenge Design Contest 2018, Anchored by NSRCEL, IIM Bangalore -(proceeded till quarterfinal- among top 300 teams))",
        patent:"Nil",
    },  
    {
        title: "GO-KART MANUFACTURING",                
        description:["Innovation part in Go-kart and bid for national karting event conducted by Sumathi motors and Barath Formula Kart Championship (BFKCT). ",
        "The product was funded by SNR sons and trust, and self."],
        technology:"ESP8266(Node MCU) and Android application.",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"",
        status:"College level project",
        patent:"Nil",
    },  
    {
        title: "HOME AUTOMATION",                
        description:["Automizing home by controlling all the home appliances through standalone web server using IoT.",
        "Redefining the way of interaction between electrical devices and consumers."],
        technology:"IoT (Internet of Things) and Android application.",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"",
        status:"Integrated Design Project from Universiti Malaysia Perlis (UniMAP), Malaysia.",
        patent:"Nil",
    },  
    {
        title: "LOW COST PAPER BAG MAKING MACHINE",                
        description:["To help the small-scale paper bag manufacturers with a cost effective, easy to operate and an automatic paper bag making machine that would bridge the price gap between the manual and cliche automated machines. "],
        technology:"PLC (Programmable Logical Controller)",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"",
        status:"Participated in IEEE YESIST12 and won special award in INNOVATION TRACK representing India at Stamford University, Bangkok, Thailand. Nominated for EO-GSEA (Entrepreneurship Organization- Global Student Entrepreneur Award) South Asian level.",
        patent:"Nil",
    },  
    {
        title: "AUGMENTED REALITY BASED LIBRARY UTILIZATION SYSTEM TO EASE THE BOOK CHOOSING",                
        description:["Augmented reality brings a virtual object to real world. ",
    "The article is directed while optimizing the traditional way of searching for books and browsing library through AR technology",
"Just scanning the corner icon of a book that lines up, a book description can to be reviewed and enjoyed. ",
"It brings an immersive experience of bringing virtual object into the real world that adhere the rules of physics for complete AR experience."],
        technology:"Augmented Reality",
        link:"https://www.ducenit.com/",
        image: "https://www.ducenit.com/wp-content/uploads/2020/08/Ducen_Logo_340x120.png",
        video:"",
        status:"Final Year Project",
        patent:"Nil",
    },    
];
export default function Project(){
    return(
        <div> 
            <Paper elevation={3} style={{padding:"10px"}}> 
            <br/>
                <Typography variant="h6" style={{marginLeft:"20px",marginBottom:"10px"}}>
                Projects         
                </Typography>
                <Divider />   
                <br/>    
                {internshipDetails.map((data) => (    
                    <Container>                
                        <Grid container spacing={3}>       
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h5" >
                                    {data.title}
                                </Typography>                                                             
                                <br/>                                
                                <Typography variant="h8" >
                                    Techonology : {data.technology}
                                </Typography>   
                                <br/>
                                <Typography variant="h8" >
                                   Patents : {data.patent}
                                </Typography>  
                                <br/>
                                <Typography variant="h8" >
                                    Status : {data.status}
                                </Typography> 
                                <br/>   
                                <Typography variant="h8" >
                                {data.description.map((item) =>                           
                                        <ul>* {item}</ul>
                                    )}
                                </Typography>                                
                               
                            </Grid>
                        <Grid item xs={12} sm={4}> 
                            <Container style={{alignContent:"center"}}>
                                {data.video !== "" ? <Paper elevation={3} style={{padding:"20px", alignContent:"center"}}>                   
                                   <ReactPlayer
                                        url={data.video}
                                        width="100%"
                                        height="200px"
                                    />                                                                   
                                </Paper> : <></>} 
                            </Container> 
                            <br/> 
                        </Grid>       
                    </Grid> 
                    <Divider />
                    <br/>
                </Container>     
                ))} 
            </Paper>       
        </div>
    );
}