import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Home from './Home.jsx';
import Academic from './Academic.jsx';
import InternShip from './Internship'
import Project from './Project'
import Acheivement from './Acheivement'
import Footer from './Footer'
const sections = [
  { title: 'Home', url: '/home' },
  { title: 'Academic', url: '/Academic' },
  { title: 'InternShip', url: '/InternShip' },
  { title: 'Project', url: '/Project' },
  { title: 'Achievement', url: '/Achievement' },  
];

function App() {
  const [page,setPage] = useState("");  
  const renderPage =(page)=>{
    setPage(page);
  }
  console.log(page)
  return (
    <React.Fragment>
      <Router>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header UserName={"Preethi Hariharan"} title="PortFolio" renderPage={renderPage}/>  
          {page==="home" || page===""?
            <Home /> :""   
          } 
          {page==="Academic"?
           <Academic /> :""   
          }  
          {page==="InterShip"?
            <InternShip /> :""   
          }  
          {page==="Project"?
            <Project /> :""   
          }  
          {page==="Achievement"?
           <Acheivement /> :""   
          }        
        <Footer title="PortFolio" description="Personalized portfolio Created by Preethi Hariharan" />  
        </Container>         
      </Router>  
    </React.Fragment>
  );
}

export default App;
